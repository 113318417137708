export function arrayDiff(arr1: string[], arr2: string[]) {
  const diff = arr1
    .filter((item) => !arr2.includes(item))
    .concat(arr2.filter((item) => !arr1.includes(item)));
  return diff;
}

export function concatUniqueObject(arr1: any[], arr2: any[]) {
  // Create a map to store unique objects by their id
  const map = new Map();

  // Helper function to add objects to the map
  function addToMap(array: any[]) {
    array.forEach((item) => {
      if (!map.has(item.id)) {
        map.set(item.id, item);
      }
    });
  }

  // Add both arrays to the map
  addToMap(arr1);
  addToMap(arr2);

  // Convert the map values back to an array
  return Array.from(map.values());
}
