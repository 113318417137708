'use client';

import { useCommonTranslation } from '@/hooks/i18n';
import { INavRoute } from '@/interfaces';
import { Link, usePathname } from '@/lib/shared-pathname';
import { getKey } from '@/utils';
import { twMerge } from 'tailwind-merge';

interface IDesktopRouteMenuProps {
  routes: INavRoute[];
}

export default function DesktopRouteMenu(props: IDesktopRouteMenuProps) {
  const { routes } = props;
  const commonTrans = useCommonTranslation();
  const currentPathname = usePathname();

  return (
    <ul className='space-x-8 text-zinc-500'>
      {routes.map((route) => (
        <li
          className={twMerge(
            'ml-2 inline',
            currentPathname === route.href && 'text-primary'
          )}
          key={getKey(route.name)}
        >
          <Link href={route.href}>{commonTrans(route.name)}</Link>
        </li>
      ))}
    </ul>
  );
}
