'use client';

import { INavRoute } from '@/interfaces';
import { Link } from '@/lib/shared-pathname';
import LangSwitcher from '../lang-switcher';
import Logo from '../logo';
import DesktopRouteMenu from './desktop-route-menu';
import MobileRouteMenu from './mobile-route-menu';

interface INavbarProps {
  children?: React.ReactNode;
  routes: INavRoute[];
  footer?: React.ReactNode;
  hideDesktopMenu?: boolean;
  rightAddon?: React.ReactNode;
}

export default function Navbar(props: INavbarProps) {
  const { routes, footer, hideDesktopMenu = false, rightAddon } = props;

  return (
    <div className='navbar sticky top-0 z-50 flex items-center justify-between gap-4'>
      <div className='flex items-center gap-4'>
        <span className='flex lg:hidden'>
          <MobileRouteMenu routes={routes} footer={footer} />
        </span>
        <Link href='/' className='cursor-pointer'>
          <Logo variant='text' />
        </Link>
      </div>
      {props.children}
      {!hideDesktopMenu && (
        <span className='hidden lg:block'>
          <DesktopRouteMenu routes={routes} />
        </span>
      )}
      {rightAddon}
      <div className='hidden items-center gap-4 lg:flex'>
        <LangSwitcher />
      </div>
    </div>
  );
}
