'use client';

import { LocaleCode } from '@/constants';
import { Link, usePathname } from '@/lib/shared-pathname';
import { Popover } from 'antd';
import { Globe } from 'lucide-react';
import { useLocale } from 'next-intl';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Option {
  country: string;
  code: string;
  flag: string;
}

interface ILangSwitcherProps {
  children?: ReactNode;
}

const LangSwitcher = (props?: ILangSwitcherProps) => {
  const pathname = usePathname();

  const options: Option[] = [
    { country: 'English', code: LocaleCode.en, flag: '🇺🇸' },
    { country: 'Tiếng Việt', code: LocaleCode.vi, flag: '🇻🇳' },
  ];

  const currentLocale = useLocale();

  return (
    <Popover
      placement='bottomRight'
      content={
        <div className='space-y-1'>
          {options.map((option) => (
            <Link
              className='relative flex cursor-pointer items-center rounded-sm px-2 py-1.5 text-sm hover:bg-zinc-50'
              key={option.code}
              href={pathname}
              locale={option.code as LocaleCode}
            >
              <span className='mr-2'>{option.flag}</span>
              <span
                className={twMerge(
                  'text-zinc-500',
                  currentLocale === option.code && 'text-primary'
                )}
              >
                {option.country}
              </span>
            </Link>
          ))}
        </div>
      }
    >
      {props?.children ?? (
        <Globe
          strokeWidth={1.5}
          className='cursor-pointer stroke-zinc-500 hover:stroke-primary'
          size={22}
        />
      )}
    </Popover>
  );
};

export default LangSwitcher;
