import { useInfoTranslation } from '@/hooks/i18n';
import dayjs, { type Dayjs } from 'dayjs';

export const roundMinuteStep = (date: dayjs.Dayjs, step: number) => {
  const minute = date.minute();
  const nextMinute = Math.ceil(minute / step) * step;
  return date.minute(nextMinute);
};

export function prettyDate(
  t: ReturnType<typeof useInfoTranslation>,
  time: string | Date
) {
  const date = new Date(time),
    diff = (Date.now() - date.getTime()) / 1000,
    dayDiff = Math.floor(diff / 86400);

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff > 182) {
    return;
  }
  const monthDiff = Math.floor(dayDiff / 30);
  return (
    (dayDiff === 0 &&
      ((diff < 60 && t('DateTime.JustNow')) ||
        (diff < 120 && t('DateTime.AMinuteAgo')) ||
        (diff < 3600 &&
          t('DateTime.MinutesAgo', { number: Math.floor(diff / 60) })) ||
        (diff < 7200 && t('DateTime.AHourAgo')) ||
        (diff < 86400 &&
          t('DateTime.HoursAgo', {
            number: Math.floor(diff / 3600),
          })))) ||
    (dayDiff === 1 && t('DateTime.Yesterday')) ||
    (dayDiff < 7 && t('DateTime.DaysAgo', { number: dayDiff })) ||
    (dayDiff < 31 &&
      t('DateTime.WeeksAgo', { number: Math.ceil(dayDiff / 7) })) ||
    (monthDiff < 1 && t('DateTime.AMonthAgo')) ||
    t('DateTime.MonthsAgo', { number: monthDiff })
  );
}

export function prettyDatePlay(
  t: ReturnType<typeof useInfoTranslation>,
  time: string | Date
) {
  const date = new Date(time),
    diff = (date.getTime() - Date.now()) / 1000,
    dayDiff = Math.floor(diff / 86400);

  if (isNaN(dayDiff) || dayDiff < 0 || dayDiff > 182) {
    return;
  }
  const monthDiff = Math.floor(dayDiff / 30);

  return (
    (dayDiff === 0 &&
      ((diff < 3600 &&
        t('DateTime.MinutesLater', { number: Math.floor(diff / 60) })) ||
        (diff < 7200 && t('DateTime.AHourLater')) ||
        (diff < 86400 &&
          t('DateTime.HoursLater', {
            number: Math.floor(diff / 3600),
          })))) ||
    (dayDiff === 1 && t('DateTime.Tomorrow')) ||
    (dayDiff < 7 && t('DateTime.DaysLater', { number: dayDiff })) ||
    (dayDiff < 31 &&
      t('DateTime.WeeksLater', { number: Math.ceil(dayDiff / 7) })) ||
    (monthDiff < 1 && t('DateTime.ANextMonth')) ||
    t('DateTime.MonthsLater', { number: monthDiff })
  );
}

export const getFromTo = (props: { from: Dayjs; to: Dayjs; date: Dayjs }) => {
  const fromHour = props.from.get('hour');
  const fromMinute = props.from.get('minute');
  const from = props.date
    .hour(fromHour)
    .minute(fromMinute)
    .second(0)
    .toISOString();

  const toHour = props.to.get('hour');
  const toMinute = props.to.get('minute');
  const to = props.date.hour(toHour).minute(toMinute).second(0).toISOString();

  return {
    from,
    to,
  };
};

export const getTimeRange = (start: string, end: string) => {
  const from = dayjs(start);
  const to = dayjs(end);

  return `${from.format('HH:mm')} - ${to.format('HH:mm')}`;
};
