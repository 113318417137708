export function getDirtyValues(
  dirtyFields: Record<string, any>,
  values: Record<string, any>
): Record<string, any> {
  const dirtyValues: Record<string, any> = {};
  Object.keys(dirtyFields).forEach((key) => {
    dirtyValues[key] = values[key];
  });
  return dirtyValues;
}
