'use client';

import { Button } from '@/components/ui/button';
import { useDisclosure } from '@/hooks/disclosure.hook';
import { useCommonTranslation } from '@/hooks/i18n';
import { INavRoute } from '@/interfaces';
import { Link, usePathname } from '@/lib/shared-pathname';
import { getKey } from '@/utils';
import { Drawer } from 'antd';
import { Menu } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import LangSwitcher from '../lang-switcher';
import Logo from '../logo';
interface IMobileRouteMenuProps {
  routes: INavRoute[];
  footer?: React.ReactNode;
}

export default function MobileRouteMenu(props: IMobileRouteMenuProps) {
  const { routes } = props;
  const commonTrans = useCommonTranslation();
  const currentPathname = usePathname();
  const disclosure = useDisclosure();

  return (
    <>
      <Menu className='text-zinc-500' onClick={disclosure.open} />
      <Drawer
        mask={false}
        closable={false}
        open={disclosure.isOpen}
        width={'100%'}
        placement='left'
        className='glassmorphism rounded-none text-zinc-500 outline-none'
        footer={
          <>
            {props.footer}
            <Button
              onClick={disclosure.close}
              variant='link'
              className='w-full text-red-500'
            >
              {commonTrans('Close')}
            </Button>
          </>
        }
      >
        <div className='mt-4 flex flex-col gap-4 px-8'>
          <div className='flex items-center justify-between'>
            <Logo variant='text' />
            <LangSwitcher />
          </div>
          <ul className='mt-4 space-y-4'>
            {routes.map((route) => (
              <li key={getKey(route.name)}>
                <Link
                  className={twMerge(
                    'ml-2 text-zinc-500 hover:text-primary',
                    currentPathname === route.href && 'text-primary'
                  )}
                  href={route.href}
                >
                  {commonTrans(route.name)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
    </>
  );
}
