import { AppRoute } from '@/interfaces';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const paths = {
  profile(username: string) {
    return (
      window.location.origin +
      AppRoute.MY_PROFILE.replace('my-profile', username)
    );
  },
  gameDetail(id: number) {
    return window.location.origin + `${AppRoute.GAME}/${id}`;
  },
};
